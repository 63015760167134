<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Crear Post
      </b-card-title>
    </b-card-header>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <!-- Title -->
        <validation-provider
          #default="validationContext"
          name="Titulo"
          rules="required"
        >
          <b-form-group
            label="Titulo"
            label-for="blog-title"
          >
            <b-form-input
              id="blog-title"
              v-model="blogData.title"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Content -->
        <validation-provider
          #default="validationContext"
          name="Contenido"
          rules="required"
        >
          <b-form-group
            label="Contenido"
            label-for="blog-content"
          >
            <quill-editor
              id="blog-content"
              v-model="blogData.content"
              class="border-bottom-0"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Categoria -->
            <validation-provider
              #default="validationContext"
              name="Categoria"
              rules="required"
            >
              <b-form-group
                label="Categoria"
                label-for="blog-category"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="blogData.categoryId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryOptions"
                  label="title"
                  :reduce="(option) => option.id"
                  class="assignee-selector"
                  input-id="blog-category"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Image Desktop -->
            <validation-provider
              #default="validationContext"
              name="Imagen"
              rules="required"
            >
              <b-form-group
                label="Imagen"
                label-for="image"
              >
                <b-form-file
                  id="image"
                  ref="refInputElDesktop"
                  v-model="blogData.image"
                  :state="getValidationState(validationContext)"
                  placeholder="Elegir imagen..."
                  accept="image/*"
                  @input="renderDesktop"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div
              v-if="showImageDesktop"
              class="my-1"
            >
              <b-img
                thumbnail
                fluid
                :src="showImageDesktop"
                alt="Image Desktop"
              />
            </div>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ blogData.id ? 'Actualizar' : 'Crear ' }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="$router.push({ name: 'blogs'})"
          >
            Cancelar
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BCard, BCardHeader, BCardTitle, BRow, BCol, BFormFile, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import blogStoreModule from '@/views/dramox/blog/blogStoreModule'
import { required, alphaNum, email } from '@validations'
import countries from '@/@fake-db/data/other/countries'
import router from '@/router'

export default {
  name: 'CreateOrEditBlog',
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormFile,
    BImg,

    // 3rd party packages
    vSelect,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-blogs'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, blogStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const blankBlogData = {
      title: '',
      content: '',
      image: null,
      categoryId: null,
    }
    const refInputElDesktop = ref(null)
    const categoryOptions = ref([])

    const showImageDesktop = ref(null)

    const blogData = ref(JSON.parse(JSON.stringify(blankBlogData)))
    const resetSliderData = () => {
      blogData.value = JSON.parse(JSON.stringify(blankBlogData))
      showImageDesktop.value = null
    }

    const { inputImageRenderer: renderDesktop } = useInputImageRenderer(refInputElDesktop, base64 => {
      // eslint-disable-next-line no-param-reassign
      showImageDesktop.value = base64
    })

    const onSubmit = () => {
      const formData = new FormData()
      formData.append('title', blogData.value.title)
      formData.append('content', blogData.value.content)
      formData.append('image', blogData.value.image)
      formData.append('categoryId', blogData.value.categoryId)

      store.dispatch('app-blogs/addBlog', { body: formData })
        .then(() => {
          router.push({ name: 'blogs' })
          showNotification({ title: 'Post creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    store.dispatch('app-blogs/fetchBlogCategoryList')
      .then(response => {
        const { results } = response.data.payload
        categoryOptions.value = results
      })
      .catch(() => {
        showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
      })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSliderData)

    return {
      blogData,
      refInputElDesktop,
      showImageDesktop,
      onSubmit,

      categoryOptions,

      refFormObserver,
      getValidationState,
      resetForm,
      renderDesktop,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
